import React, { useEffect, useState } from 'react';
import "./dashboard.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as action from '../../actions';
import UserModal from '../elements/userModal/';
import { Modules, ApiUrls } from '../../constants';
import Request from '../../services/Request';
import { NewsRenderer, NewsType } from '../../helper/News';

import BreakingNews from '../elements/breakingNews/BreakingNews';
import PortalComponent from "../portal";
import { CommonHelper } from '../../helper/Common';
import NotificationsComponent from "../notifications";
import Indicator from "../elements/indicator/indicator";
import Urba from "../elements/urba/urba";

const DashboardComponent = (props: any) => {
    const { t } = useTranslation();
    const [news, setNews] = useState([])
    const [alerts, setAlerts] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        Request.get(ApiUrls.GET_NEWS, false, true)
            .then(res => {
                setNews(res.data.news)
                setAlerts(res.data.alerts)
                setLoading(false)
            })
            .catch(error => {
                setError(true)
                setLoading(false)
            })
    }, [])

    let modal = null;

    if (props.ui.userModal.show) {
        modal = <UserModal initialTab={props.ui.userModal.tab} />
    }

    const showTile = (moduleName: string) => {
        const { roles } = props.user.info
        const requiredRole = CommonHelper.getUserRoleFromModule(moduleName)

        return roles.includes(requiredRole)
    }

    return (
        <>
            {modal}
            <div className="container-fluid mt-4 overflow-auto h-100">
                <div className='row'>
                    <div className="col-md-6">
                        <h1 className="mb-5">{t('welcome_user', { user: props.user.info.firstName })}</h1>
                    </div>
                    <div className="col-md-6 extranet-alerts">
                        {NewsRenderer.renderNewsElement(alerts, NewsType.ALERT, props.loading, props.error)}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-3">
                        <BreakingNews news={news} loading={loading} error={error} />
                    </div>
                    <div className="col-md-9">
                        {showTile(Modules.portal) && <PortalComponent />}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-12">
                        <NotificationsComponent />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-6">
                        {showTile(Modules.indicators) && <Indicator />}
                    </div>
                    <div className="col-md-6">
                        {showTile(Modules.urbanism) && <Urba />}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user,
    ui: state.ui,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        showModal: (tab: string) => dispatch(action.showModal(tab)),
        hideModal: () => dispatch(action.hideModal()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardComponent);