import React, { useRef } from 'react';
import { LatLng, Map } from 'react-leaflet';
import "./map.scss";

interface IMapComponent {
    center: LatLng,
    children: any,
    geoShapes: any,
    mapUri: any,
    maxBounds?: LatLng[],
    onClick?: Function,
    zooms: any
}

const MapComponent = (props: IMapComponent) => {
    const { children, center, zooms, maxBounds, onClick } = props;
    const mapRef = useRef<any>();

    return (
        <Map
            ref={mapRef}
            center={center}
            {...zooms}
            maxBounds={maxBounds}
            onclick={onClick}
        >
            {children}
        </Map>
    );
};

export default MapComponent;
