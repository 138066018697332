import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import userReducer from "./user_reducer";
import loadingReducers from "./loading_reducer";
import errorReducers from "./error_reducer";
import uiReducer from "./ui_reducer";

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    loading: loadingReducers,
    error: errorReducers,
    ui: uiReducer,
});

export default rootReducer;
