import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from "react-ga";
import { config } from "./config";
import { AppUrls, ErrorRoutes } from './constants';
import DashboardComponent from './components/dashboard';
import Logout from "./pages/logout";
import ErrorManager from './manager/error';
import NavigationManager from './manager/navigation';
import { AuthenticateService } from './services/AuthenticateService';
import { store } from './store';
import cssVars from 'css-vars-ponyfill';

cssVars({
    onlyLegacy: true,
});

interface IAppProps {
    history: History;
}

const AppRouter = ({ history }: IAppProps) => {
    useEffect(() => {
        if (config.googleAnalyticsId) {
            ReactGA.initialize(config.googleAnalyticsId);
            history.listen(location => {
                ReactGA.set({ page: location.pathname }); // Update the user's current page
                ReactGA.pageview(location.pathname); // Record a page view for the given page
            });
        }
    });

    useEffect(() => {
        const pathName = history.location.pathname;
        const token = localStorage.getItem('token-atlas-extranet');
        if ((!token || token === 'undefined') && pathName !== ErrorRoutes.SSO_ERROR) {
            AuthenticateService.login();
        } else if (pathName !== ErrorRoutes.SSO_ERROR) {
            AuthenticateService.checkToken();
        }
    }, [history]);

    return (
        <Suspense fallback={<div/>}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <NavigationManager>
                        <Switch>
                            <Route exact path={AppUrls.LOGOUT} component={Logout} />
                            <Route exact path={AppUrls.HOME} component={DashboardComponent}/>
                            <Route exact
                                   path={[ErrorRoutes.NOT_FOUND, ErrorRoutes.ACCESS_DENIED, ErrorRoutes.OOPS, ErrorRoutes.SSO_ERROR]}
                                   component={ErrorManager}/>
                            <Route render={() => (<Redirect to={AppUrls.HOME}/>)}/>
                        </Switch>
                    </NavigationManager>
                </ConnectedRouter>
            </Provider>
        </Suspense>
    );
}

export default AppRouter;
