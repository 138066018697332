import React from 'react';
import {SzAlert, SzTypographie, SzSpinner} from 'react-theme-components';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export enum NewsType {
    ALERT = 'alert',
    NEWS = 'news',
}

export interface iNews {
    id: number,
    title: string,
    content: string,
    severity: 'warning' | 'info' | 'danger'
}

export class NewsRenderer {
    public static renderNewsElement(news:iNews[], type:NewsType, loading:boolean, error:boolean) {
        const { t } = useTranslation();

        if (loading) {
            return <div className='text-center p-2'><SzSpinner variant='secondary'/></div>
        }

        // If an error occured, show warning
        if (error) {
            const text = (NewsType.ALERT === type) ? t('ALERT_error') : t('NEWS_error');

            return [
                <SzAlert transparent={true} variant='warning' key={0}>
                    <div className='py-4 alert-text'>
                        <SzTypographie variant='body' weight='bold'>{text}</SzTypographie>
                    </div>
                </SzAlert>
            ]
        }

        // If loading is finished and news is empty → no news to show
        if (news.length === 0) {
            // Don't return anything if there is no alert.
            if (type === NewsType.ALERT) {
                return null
            }

            return [
                <SzAlert transparent={true} variant='info' key={0}>
                    <div className='py-4 alert-text'>
                        <SzTypographie variant='body' weight='bold'>{t('NEWS_none')}</SzTypographie>
                    </div>
                </SzAlert>
            ]
        }

        // Beyond this point we have news that are properly loaded
        return news.map((newsItem:iNews) => {
            return (<SzAlert transparent={(NewsType.ALERT !== type)} variant={newsItem.severity} key={newsItem.id}>
                <div className='py-4 alert-text'>
                    <SzTypographie variant='body' weight='bold'>{parse(newsItem.content)}</SzTypographie>
                </div>
            </SzAlert>)
        })
    }
}
