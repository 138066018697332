import axiosService from './Request';
import { ApiUrls } from '../constants';

export class SubscriptionsService {
    public static getSubscriptions() {
        return axiosService
            .get(ApiUrls.GET_SUBSCRIPTIONS, false)
            .then((response) => {
                return response.data;
            });
    }

    public static getSubscriptionsGrouped() {
        return this.getSubscriptions().then((subscriptions) => {
            let subscriptionsGrouped: any = {};
            subscriptions.forEach((value: any) => {
                const { enabled, mode, module } = value;
                let moduleArray = subscriptionsGrouped[module.id] || [];
                subscriptionsGrouped = { ...subscriptionsGrouped, [module.id]: { ...moduleArray, [mode.id]: enabled } }
            });

            return subscriptionsGrouped;
        });
    }
}
