import axiosService from './Request';
import {AxiosResponse} from "axios";
import {IndicatorReportingResponse} from "../types/indicator.type";

export class IndicatorRepository {
    public static getReporting(): Promise<AxiosResponse<IndicatorReportingResponse>> {
        const url = '/api/indicators/reporting';
        return axiosService.get(url, false);
    }
}
