import L from 'leaflet';

const circle = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -5 32 32"><title/><circle cx="12" cy="11" r="13" stroke="white" stroke-width="4" stroke-linejoin="round" fill="white"/><circle cx="12" cy="11" r="13"/></svg>';

const abandonnedIcon = new L.DivIcon({
    html: circle,
    iconSize: new L.Point(14, 14),
    className: 'leaflet-marker-icon intervention-icon__abandonned'
});

const doneIcon = new L.DivIcon({
    html: circle,
    iconSize: new L.Point(14, 14),
    className: 'leaflet-marker-icon intervention-icon__done'
});

const ongoingIcon = new L.DivIcon({
    html: circle,
    iconSize: new L.Point(14, 14),
    className: 'leaflet-marker-icon intervention-icon__ongoing'
});

const plannedIcon = new L.DivIcon({
    html: circle,
    iconSize: new L.Point(14, 14),
    className: 'leaflet-marker-icon intervention-icon__planned'
});

type tIcons = {
    [key: string]: L.DivIcon
}

const icons: tIcons = {
    'abandonned': abandonnedIcon,
    'done': doneIcon,
    'ongoing': ongoingIcon,
    'planned': plannedIcon,
};

export default icons;
