import axiosService from './Request';
import {AxiosResponse} from "axios";
import {UrbaReportingResponse} from "../types/urba.type";

export class UrbaRepository {
    public static getReporting(): Promise<AxiosResponse<UrbaReportingResponse>> {
        const url = '/api/urba/reporting';
        return axiosService.get(url, false);
    }
}
