import React, { useEffect, useState } from 'react';
import "./portal.scss";
import { useTranslation } from "react-i18next";
import { SzBox, SzAlert, SzIcon, SzTypographie, SzSpinner } from "react-theme-components";
import PortalManager from "../../manager/portal/PortalManager";
import { ReactComponent as CircleIcon } from "../map/svg/circle.svg";
import {config} from '../../config';
import {ApiUrls} from '../../constants';
import Request from '../../services/Request';

const PortalComponent = () => {
    const { t } = useTranslation();

    const [ interventions, setInterventions ] = useState([])
    const [ count, setCount ] = useState({ongoing: 0, planned: 0 })
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(false)

    useEffect(() => {
        Request.get(ApiUrls.GET_INTERVENTIONS, false, true)
        .then(res => {
            const { count, interventions } = res.data

            setInterventions(interventions)
            setLoading(false)
            setCount(count)
        })
        .catch(error => {
            setLoading(false)
            setError(true)
        })
    }, [])

    const renderCounts = () => {
        if (loading) {
            return <div className="text-center"><SzSpinner /></div>
        }

        if (error) {
            return (<SzAlert transparent={true} variant='warning'>
                <div className='py-4 alert-text'>
                    <SzTypographie variant='body' weight='bold'>{t('intervention_error')}</SzTypographie>
                </div>
            </SzAlert>)
        }

        return (<ul className="legend-inline">
            <li className="status-ongoing">
                <SzTypographie variant="caption" weight="medium">
                    <CircleIcon className="icon intervention-icon__ongoing mr-2"/>
                    {t('intervention_ongoing', { count: count.ongoing })}
                </SzTypographie>
            </li>
            <li className="status-planned">
                <SzTypographie variant="caption" weight="medium">
                    <CircleIcon className="icon intervention-icon__planned mr-2"/>
                    {t('intervention_planned', { count: count.planned })}
                </SzTypographie>
            </li>
        </ul>)
    }

    return (
        <section className="h-100">
            <SzBox className="box--no-padding cat-portal h-100" tag="div">
                <header className="m-5">
                    <SzTypographie variant="h2" weight="bold"
                                   className="d-flex align-items-center justify-content-between mb-5">
                        {t('portal')}
                        <a href={config.PortalModuleUrl} target="_blank" rel="noopener noreferrer">
                            <SzIcon variant="bold" icon="arrow-right"/>
                        </a>
                    </SzTypographie>
                    {renderCounts()}
                </header>
                <article>
                    <div className="row">
                        <div className="col map-container">
                            <PortalManager interventions={interventions}/>
                        </div>
                    </div>
                </article>
            </SzBox>
        </section>
    );
};

export default PortalComponent;
