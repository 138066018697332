// action strings
export enum Constants {
    DATA_LOADING = "DATA_LOADING",
    ERROR = 'ERROR',
    ERROR_INTERVENTION = "ERROR_INTERVENTION",
    LOG_IN = "login",
    LOG_OUT = "logout",
    NOTIFICATIONS_PAGE_RANGE = 5,
    NOTIFICATIONS_PER_PAGE = 20,
    UI_SHOW_MODAL = "ui_show_modal",
    UI_HIDE_MODAL = "ui_hide_modal",
}

export enum Pagination {
    PAGINATION_TOTAL_COUNT = 'x-pagination-total-count',
    PAGINATION_PAGE_COUNT = 'x-pagination-page-count',
    PAGINATION_CURRENT_PAGE = 'x-pagination-current-page',
    PAGINATION_PER_PAGE = 'x-pagination-per-page'
}

export enum Map {
    DEFAULT_LAT = 43.600000,
    DEFAULT_LNG = 1.433333,
    OSM_URI = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    ZOOM_DEFAULT = 11,
    ZOOM_MIN = 6,
    ZOOM_MAX = 19
}

export enum Modules {
    documents = 'ged',
    indicators = "ind",
    portal = "tsms",
    requester = "req",
    requests = "pce",
    urbanism = "urba",
    itv = "itv",
    vis = "vis",
}

export enum Modes {
    email = 'email',
    portal = 'portal'
}

export enum Notifications {
    documents = 'GED',
    indicators = "IND",
    // portal = "tsms",
    requests = "PCE",
    urbanism = "urba"
}


export enum Roles {
    ROLE_SUEZ = 'ROLE_SUEZ',
    ROLE_CLIENT = 'ROLE_CLIENT',
    ROLE_ADMIN = 'ROLE_ADMIN'
}

export enum HttpCode {
    NOT_FOUND = 404,
    ACCESS_DENIED = 401,
    OOPS = 500,
    UNSUPPORTED_MEDIA = 415
}

export enum AppUrls {
    HOME = '/',
    HELP = '/help',
    LOGOUT = '/logout',
}

export enum ErrorRoutes {
    NOT_FOUND = '/error/unknown',
    ACCESS_DENIED = '/error/access-denied',
    OOPS = '/error/oops',
    SSO_ERROR = '/error/sso'
}

export enum ApiUrls {
    POST_SUBSCRIPTIONS = '/api/subscriptions',
    DELETE_SUBSCRIPTIONS = '/api/notifications/{id}',
    GET_INTERVENTIONS = '/api/interventions',
    GET_NEWS = '/api/news',
    GET_NOTIFICATIONS = '/api/notifications',
    GET_REQUEST_COUNT = '/api/requests-stats',
    GET_SUBSCRIPTIONS = '/api/subscriptions',
    TOKEN_INFO_URL = '/api/token-check'
}

export enum Status {
    DRAFT = "draft" as any,
    TO_QUALIFY = "to-qualify" as any,
    TO_SPECIFY = "to-specify" as any,
    TO_PLAN = "to-plan" as any,
    ABANDONNED = "abandonned" as any,
    PLANNED = "planned" as any,
    ONGOING = "ongoing" as any,
    PREMISE_DONE = "premise-done" as any,
    DONE = "done" as any,
    CLOSED_REJECTED = "closed-rejected" as any,
    CLOSED = "closed" as any
}

export enum StatusMap {
    ONGOING = 'Enc' as any,
    PLANNED = 'Pla' as any,
}

export enum ModalTabs {
    PROFILE = 'profile',
    ALERTS = 'alerts',
    MODULES = 'modules',
}