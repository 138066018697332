import React, {useEffect, useState} from 'react';
import {SzBox, SzIcon, SzTypographie} from "react-theme-components";
import {config} from "../../../config";
import {useTranslation} from "react-i18next";
import {IndicatorReportingResponse} from "../../../types/indicator.type";
import {groupTypeAndFilter, StatusFiltered} from "./service";
import "./indicator.scss";
import {IndicatorRepository} from "../../../services/IndicatorRepository";

const Indicator: React.FC = () => {
    const {t} = useTranslation('indicator');

    const [reports, setReports] = useState<IndicatorReportingResponse>([]);

    useEffect(() => {
        IndicatorRepository.getReporting().then(({data}) => setReports(data));
    }, []);

    const renderReporting = () => {
        if (!reports || (Array.isArray(reports) && reports.length === 0)) {
            return null;
        }

        if (!Array.isArray(reports[0]['statusReport']) || reports[0]['statusReport'].length === 0) {
            return null;
        }

        const statusFiltered = groupTypeAndFilter(reports[0]['statusReport'][0]['statuses']);

        return (Object.keys(statusFiltered) as Array<keyof StatusFiltered>).map((key) => {
            if (undefined === statusFiltered[key] || 0 === statusFiltered[key]) {
                return null;
            }

            return <div key={key} className="metric">
                <span className="metric-value">{
                    statusFiltered[key]
                }</span>
                <strong className="metric-name">{t(key)}</strong>
            </div>
        });
    }

    return <div className="indicator-wrapper">
        <SzBox className="box--no-padding card h-100" tag="div">
            <header className="m-5">
                <SzTypographie variant="h2" weight="bold" className="d-flex align-items-center justify-content-between mb-5">
                    {t('title')}
                    <a href={config.indicatorModuleUrl} target="_blank" rel="noopener noreferrer">
                        <SzIcon variant="bold" icon="arrow-right"/>
                    </a>
                </SzTypographie>
            </header>
            <article className="d-flex mb-2">
                {renderReporting() ?? <div className="col">{t('no_indicator')}</div>}
            </article>
        </SzBox>
    </div>
}

export default Indicator;