import React, { useState } from 'react';
import { SzModal, SzTab } from "react-theme-components";
import { connect } from "react-redux";
import * as action from '../../../actions/';
import ProfileTab from './profileTab';
import AlertsTab from './alertsTab';
import ModulesTab from './modulesTab';
import './userModal.scss';
import { useTranslation } from "react-i18next";

const UserModal = (props: any) => {
    const { t } = useTranslation();

    const [tab, setTab] = useState(props.initialTab);

    const selectTabHandle: any = (tab: any) => {
        setTab(tab);
    };

    return (
        <SzModal
            title=''
            show={true}
            handleClose={props.hideModal}
            className='user-modal'
            size={'lg'}
        >
            <SzTab
                activeKey={tab}
                id="userModalTabs"
                selectTabHandle={selectTabHandle}
            >
                <SzTab.SzTabContent eventKey='profile' title={t('USER_INFO_tab_profile')}>
                    <ProfileTab/>
                </SzTab.SzTabContent>
                <SzTab.SzTabContent eventKey='alerts' title={t('USER_INFO_tab_alerts')}>
                    <AlertsTab/>
                </SzTab.SzTabContent>
                <SzTab.SzTabContent eventKey='modules' title={t('USER_INFO_tab_module')}>
                    <ModulesTab/>
                </SzTab.SzTabContent>
            </SzTab>
        </SzModal>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        hideModal: () => dispatch(action.hideModal()),
    }
}

export default connect(null, mapDispatchToProps)(UserModal);
