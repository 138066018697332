import React from 'react';
import { SzTypographie, SzBox } from "react-theme-components";
import { ReactComponent as DocumentsIcon } from '../../../assets/icon/modules/documents.svg';
import { ReactComponent as IndicatorsIcon } from '../../../assets/icon/modules/indicators.svg';
import { ReactComponent as PortalIcon } from '../../../assets/icon/modules/portal.svg';
import { ReactComponent as RequesterIcon } from '../../../assets/icon/modules/requester.svg';
import { ReactComponent as RequestsIcon } from '../../../assets/icon/modules/requests.svg';
import { ReactComponent as UrbanismIcon } from '../../../assets/icon/modules/urbanism.svg';
import { ReactComponent as ItvIcon } from '../../../assets/icon/modules/itv.svg';
import { ReactComponent as VisIcon } from '../../../assets/icon/modules/vis.svg';
import { Modules } from "../../../constants";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const ModulesTab = (props:any) => {
    const { t } = useTranslation();

    const iconComponents = {
        documents: { component: DocumentsIcon, nameKey: 'MODULES_documents_title', descriptionKey: 'MODULES_documents_desc', serviceRequired: Modules.documents},
        indicators: { component: IndicatorsIcon, nameKey: 'MODULES_indicators_title', descriptionKey: 'MODULES_indicators_desc', serviceRequired: Modules.indicators},
        portal: { component: PortalIcon, nameKey: 'MODULES_portal_title', descriptionKey: 'MODULES_portal_desc', serviceRequired: Modules.portal},
        requester: { component: RequesterIcon, nameKey: 'MODULES_requester_title', descriptionKey: 'MODULES_requester_desc', serviceRequired: Modules.requester},
        requests: { component: RequestsIcon, nameKey: 'MODULES_requests_title', descriptionKey: 'MODULES_requests_desc', serviceRequired: Modules.requests},
        urbanism: { component: UrbanismIcon, nameKey: 'MODULES_urbanism_title', descriptionKey: 'MODULES_urbanism_desc', serviceRequired: Modules.urbanism},
        itv: { component: ItvIcon, nameKey: 'MODULES_itv_title', descriptionKey: 'MODULES_itv_desc', serviceRequired: Modules.itv},
        vis: { component: VisIcon, nameKey: 'MODULES_vis_title', descriptionKey: 'MODULES_vis_desc', serviceRequired: Modules.vis},
    };

    return (
        <>
            <SzTypographie variant='body' className='ml-5'>{t('MODULES_tab_text')}</SzTypographie>

            {Object
                .entries(iconComponents)
                .filter((value) => {
                    const serviceRequired = 'ROLE_' + value[1].serviceRequired.toUpperCase();
                    const userRoles = props.user.roles;
                    return userRoles.includes(serviceRequired);
                })
                .map(([key, value]) => {
                    const IconComponent = value.component;
                    return (
                        <SzBox tag='div' className='row border module-description mb-3 mx-5' key={key}>
                            <div className='col-4 col-lg-3 module-icon'>
                                <IconComponent className="icon-huge"/>
                            </div>
                            <div className='col-8 col-lg-9'>
                                <SzTypographie variant='h2' weight='bold' className='mb-4'>{t(value.nameKey)}</SzTypographie>
                            </div>
                            <div className='col col-lg-9 mt-4 mt-lg-0 offset-lg-3'>
                                <SzTypographie variant='body'>{t(value.descriptionKey)}</SzTypographie>
                            </div>
                        </SzBox>
                    );
            })}
        </>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.user.info
});

export default connect(mapStateToProps, null)(ModulesTab);
