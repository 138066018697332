import { Pagination } from "../constants";

export class CommonHelper {
    public static getEnumKeyByValue(myEnum: any, enumValue: string) {
        let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
        return keys.length > 0 ? keys[0] : null;
    }

    public static getModuleFromUserRole(userRole: string) {
        return userRole.replace('ROLE_', '').toLowerCase();
    }

    public static getUserRoleFromModule(module: string) {
        return ('ROLE_' + module).toUpperCase();
    }

    public static getPaginationInfo(headers: any): any {
        let pagination: any = [];
        Object.entries(headers).map((header: any) => {
                if (Object.values(Pagination).includes(header[0])) {
                    pagination[header[0]] = header[1];
                }
                return pagination;
            }
        );

        return pagination;
    }
}
