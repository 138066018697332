import React from "react";
import "./moduleTitle.scss";
import { SzTypographie } from "react-theme-components";
import { useTranslation } from "react-i18next";

interface iModuleTitle {
    module?: string;
    className?: string;
}

export default function ModuleTitle(props: iModuleTitle) {
    const { className, module } = props;
    const { t } = useTranslation();
    const styles = ['module-title' + (module !== undefined ? '__' + module : ''), className].join(' ');
    const title = ('MODULES_%s_short').replace('%s', module !== undefined ? module : 'default');

    return (
        <div className={styles}>
            <SzTypographie weight="bold">{t(title)}</SzTypographie>
        </div>
    );
}
