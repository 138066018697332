import qs from "qs";
import { login, logout } from '../actions';
import { store } from '../store';
import { config } from '../config';
import { ApiUrls } from '../constants';
import axiosService from './Request';

export class AuthenticateService {
    public static login() {
        const { token } = qs.parse(window.location.search.substring(1));
        if (!token) {
            localStorage.setItem("redirect-atlas-extranet", window.location.href);
            window.location = config.apiUrl + '/saml/login' as any;
        } else {
            localStorage.setItem("token-atlas-extranet", token.toString());
            this.checkToken()
            let redirectHref = localStorage.getItem("redirect-atlas-extranet");
            localStorage.removeItem("redirect-atlas-extranet");
            window.location = redirectHref as any;
        }
    }

    public static checkToken() {
        axiosService.get(ApiUrls.TOKEN_INFO_URL, false)
            .then(function (response) {
                store.dispatch(login(response.data));
            })
    }

    public static logout(): boolean {
        localStorage.clear();
        store.dispatch(logout());
        window.location = config.apiUrl + '/saml/logout' as any;
        return true;
    }
}
