import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';
import L from 'leaflet';
import { LayersControl, Marker, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Map, Status, StatusMap } from '../../constants';
import { CommonHelper } from '../../helper/Common'
import MapComponent from '../../components/map'
import icons from '../../components/map/icons';

class PortalManager extends React.Component<any> {
    refMarker = createRef<Marker>();
    marker = { lat: Map.DEFAULT_LAT, lng: Map.DEFAULT_LNG };
    zooms = { zoom: Map.ZOOM_DEFAULT, minZoom: Map.ZOOM_MIN, maxZoom: Map.ZOOM_MAX };

    state = {
        showMarker: false,
        marker: this.marker,
        shapes: null
    };

    render() {
        const { t } = this.props;
        const { lat, lng } = this.state.marker;
        const { BaseLayer } = LayersControl;
        const zooms = this.zooms;

        /**
         * If only planned, planned color
         * If only ongoing, ongoing color
         * If planned & ongoing, ongoing color
         *
         * @param cluster
         */
        const createClusterCustomIcon = function (cluster: L.MarkerCluster) {
            let countOngoing = 0;
            const markers = cluster.getAllChildMarkers();
            markers.map((marker: L.Marker) => {
                const icon = marker.getIcon();
                const status = Status.ONGOING as unknown as string;
                if (undefined !== icon.options.className && icon.options.className.indexOf(status) > 0) {
                    ++countOngoing;
                }
                return countOngoing;
            });
            const className = countOngoing > 0 ? 'marker-cluster-custom__ongoing' : 'marker-cluster-custom';

            return L.divIcon({
                html: `<span>${cluster.getChildCount()}</span>`,
                className: className,
                iconSize: L.point(28, 28, true),
            });
        };

        window.dispatchEvent(new Event('resize'));

        return (
            <MapComponent
                mapUri={Map.OSM_URI}
                zooms={zooms}
                center={[lat, lng]}
                geoShapes={null}>
                <LayersControl>
                    <BaseLayer checked name={t('MAP_plan_view')}>
                        <TileLayer url={Map.OSM_URI}
                                   attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'/>
                    </BaseLayer>
                </LayersControl>
                <MarkerClusterGroup
                    iconCreateFunction={createClusterCustomIcon}
                >
                    {this.props.interventions.map((intervention: any, index: any) => {
                        const { GeolocalisationLatitude, GeolocalisationLongitude, CodeEtatIntervention } = intervention;
                        const statusKey: any = CommonHelper.getEnumKeyByValue(StatusMap, CodeEtatIntervention);

                        return GeolocalisationLatitude && GeolocalisationLongitude && statusKey !== '' ? (
                            <Marker
                                key={index}
                                icon={icons[Status[statusKey]]}
                                position={[GeolocalisationLatitude, GeolocalisationLongitude]}
                                ref={this.refMarker}
                            >
                            </Marker>
                        ) : null;
                    })}
                </MarkerClusterGroup>
            </MapComponent>
        );
    }
}

export default withTranslation()(PortalManager);
