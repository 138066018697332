import {ReportingStatus, ReportStatusId} from "../../../types/indicator.type";

export const countByType = (statuses: ReportingStatus[], types: string[]): number  => {
    let total = 0;

    statuses.forEach(status => {
        if (types.includes(status.id)) {
            total += status.count;
        }
    });

    return total;
}


export interface StatusFiltered {
    draft_or_refused?: number;
    to_approve?: number;
    to_validate?: number;
    late?: number;
}


export const groupTypeAndFilter = (statuses: ReportingStatus[]): StatusFiltered => {
    return {
        draft_or_refused: countByType(statuses, [ReportStatusId.DRAFT, ReportStatusId.REFUSED]) ?? undefined,
        to_approve: countByType(statuses, [ReportStatusId.TO_APPROVE]) ?? undefined,
        to_validate: countByType(statuses, [ReportStatusId.TO_VALIDATE]) ?? undefined,
        late: countByType(statuses, [ReportStatusId.LATE]) ?? undefined,
    };
}